.highligth{
    color: blue;
    text-decoration: underline;
}.scrollable-div {
    max-height: 410px; /* Set your desired max height */
    overflow-y: scroll;
    position: relative; /* Required for child element positioning */
  }
  .info-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    line-height: 20px;
    font-weight: bold;
    font-size: 14px;
  }
  
  
  