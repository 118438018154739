/* common start */
*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
}

.common-width {
  width: calc(100% - 150px);
  margin: auto;
}

/* common end */

/* header start */
.header {
  background: #f4f4f4;
  backdrop-filter: blur(32px);
  z-index: 999;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.header-logo-job {
  display: flex;
  gap: 106px;
  padding: 29px 0px;
}

.upper-header-bar {
  opacity: 0;
}

.header-logo {
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  text-decoration: none;

}

.header-job {
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  text-decoration: none;
  /* color: #fff; */
}
.header-menu {
  font-weight: 750;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
  text-decoration: none;
}
.header-job-active {
  font-weight: 800;
  font-size: 16px;
  line-height: 130%;
  color: #0066ff;
  text-decoration: none;
  /* transition: color 300ms linear; */
}

.header-menu-list {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;

}

@media screen and (max-width:780px) {
  .header-menu-list {
    display: none;
  }
}

.tss-dbu3l1-MUIDataTableViewCol-formGroup{
  align-items: start !important;
}

.header-search {
  position: relative;
}

.header-search-icon {
  position: absolute;
  top: 8px;
  left: 19px;
}

.header-search-input {
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  outline: none;
  height: 36px;
  padding-left: 50px;
  width: 100%;
}

.header-search-input::placeholder {
  color: #adb5bd;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
}

.Header-lower-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

}

@media screen and (max-width:1280px) {
  .Header-lower-bar {
    display: none;
  }

}

.header-user {
  display: flex;
  gap: 16px;
  padding: 15.5px 0;
  cursor: pointer;
}

.header-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  color: #232d42;
}

.header-role {
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: #8a92a6;
}

/* header end */

/* title top start */
.tt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 40px 0px;
  flex-wrap: wrap;
}

.tt-back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 21px;
  flex-wrap: wrap;
}

.tt-back-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
}

.tt-back-create {
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  color: #000000;
}

.tt-create {
  padding: 8px 24px;
  background: #068b92;
  box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.tt-dot-bar {
  position: relative;
  z-index: 40;
}

.tt-bar {
  cursor: pointer;
}

/* title top end */

/* create-candidate-start */
.cc {
  background: #f9f9f9;
  border-radius: 10px 10px 0px 0px;
  padding: 27px 31px 90px 31px;
  display: flex;
  gap: 44px;
}

.cc-left {
  overflow-y: scroll;
  height: 120vh;
  width: 70%;
}

.cc-right {
  width: 30%;
}

.cc-pd {
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  color: #000000;
}

.cc-form-content {
  display: flex;
  gap: 20px;
  margin-top: 17px;
}

.cc-label {
  font-weight: 400;
  font-size: 15px;
  line-height: 175%;
  color: #000000;
  text-align: left;
}

.cc-input {
  background: #ffffff;
  border: 0.5px solid #bfbfbf;
  border-radius: 5px;
  height: 35px;
  margin-top: 3px;
  width: 400px;
  font-family: "Inter";
  padding: 6px 14px;
  outline: 0;
}

.cc-form-select-double {
  width: 140px;
}

.cc-input::placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
}

.cc-form-select {
  position: relative;
}

.cc-form-select-icon {
  position: absolute;
  top: 45px;
  right: 13px;
}

select {
  appearance: none;
}

.cc-edu {
  margin-top: 44px;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #d1d1d1;
  border-radius: 7.5px 7.5px 0px 0px;
}

::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 7.5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 7.5px;
}

/* create-candidate-end */

/* hiring status start */
.content-top-border {
  border: 1px solid #adb5bd;
}

.ct-content {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
}

.ct-content__left {
  width: 35%;
}

.ct-content__right {
  width: 55%;
}

.ct-content-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  color: #000000;
}

.ct-content-des {
  font-weight: 400;
  font-size: 20px;
  line-height: 175%;
  color: #000000;
}

.ct-content-btn {
  background: #109cf1;
  /* box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3); */
  padding: 15px 34px;
  color: #ffffff;
  cursor: pointer;
}

.ct-content-hs-reletive {
  position: relative;
}

.ct-content-hs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #068b92;
  cursor: pointer;
}

.ct-content-btn__hs {
  background: #068b92;
}

.ct-content-btn-icon {
  margin-right: 16px;
}

.ct-content-hs-items {
  position: absolute;
  top: 56px;
  right: -1px;
  background: #ffffff;
  border: 1px solid #919191;
  border-radius: 10px;
  width: 198px;
}

.ct-content-hs-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
  padding: 18px 22px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  width: 100%;
}

/* candidate profile start */
.can-pro {
  display: flex;
}

.can-pro-left {
  margin: 19px 0;
  overflow-y: scroll;
  height: 100vh;
  padding: 0px 38px 20px 0;
  width: 70%;
}

.content-top-border__can-pro {
  margin-top: 25px;
}

.can-pro-left-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  color: #109cf1;
  margin-bottom: 19px;
}

.can-pro-left-item {
  display: flex;
  gap: 10px;
  margin-bottom: 19px;
}

.can-pro-left-content {
  margin-top: 23px;
}

.can-pro-left-item-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
  padding-right: 185px;
  width: 131px;
}

.can-pro-left-item-des {
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
}

.can-pro-left-item__work {
  border-bottom: 1px solid #adb5bd;
  margin-bottom: 0px;
  gap: 0px;
}

.can-pro-left-work-title {
  background: #e0e0e0;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
  padding: 6px 0px;
  width: 236px;
  text-align: center;
}

.can-pro-left-work-des-item {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  padding: 6px 14px;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.can-pro-left-work-des {
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
}

.can-pro-right {
  width: 30%;
  padding: 19px 24px;
  overflow-y: scroll;
  height: 100vh;
  margin: 19px 0;
}

.can-pro-right-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  color: #000000;
  text-align: center;
}

.cc-input__input {
  width: 200px;
  height: 50px;
}

.can-pro-plus {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.can-pro-right-comment {
  margin-top: 25px;
}

.can-pro-right-comment-title {
  font-weight: 700;
  font-size: 13px;
  line-height: 175%;
  color: #000000;
}

.can-pro-right-comment-des {
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #414141;
  background: #ebebeb;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 7px 20px;
  width: 100%;
}

.can-pro-right-comment-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-top: 4px;
}

.can-pro-right-comment-info-details {
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #919191;
}

/* candidate profile end */
/* hiring status end */

/* schedule interview start */
.sched-inter-content-main {
  background: #f9f9f9;
  border-radius: 10px 10px 0px 0px;
  padding: 60px 40px;
}

.sched-inter-content {
  display: flex;
  gap: 80px;
}

.sched-inter__input {
  width: 100%;
}

.sched-inter-location__label {
  width: 250px;
}

.sched-inter-content-left {
  width: 40%;
}

.sched-inter-content-left-checkbox-content {
  display: flex;
  align-items: center;
  gap: 11px;
}

.sched-inter-content-left-checkbox-main {
  display: flex;
  flex-direction: row;
  margin-top: 41px;
  gap: 37px;
  flex-wrap: wrap;
}

.sched-inter-content-left-checkbox {
  border: 2px solid #d5d5d5;
  border-radius: 4px;
}

.sched-inter-location {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.sched-inter-left-input {
  margin-top: 40px;
  display: flex;
  gap: 5px;
}

.sched-inter-select-icon {
  top: 18px;
}

.sched-inter-content-right {
  margin-top: 30px;
  width: 60%;
  margin: auto;
}

.sched-inter-content-border {
  border: 1px solid #919191;
}

.sched-inter-react-quill {
  margin-top: 40px;
}

.ql-container.ql-snow {
  background: #ffffff;
}

.quill {
  height: 130px;
}

.sched-inter-content-bottom-border {
  border: 1px solid #919191;
  margin-top: 43px;
}

.sched-inter-content-btn {
  background: #109cf1;
  box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
  border-radius: 4px;
  padding: 15px 0px;
  text-align: center;
  color: #ffffff;
  width: 213px;
  margin: 61px auto 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.sched-inter-content-btn__send {
  margin: 61px 0 0 0;
}

.sched-inter-right-file-input {
  display: none;
}

.sched-inter-right-file-text {
  background: #9b9b9b;
  border: 0.5px solid #424242;
  border-radius: 5px;
  width: 330px;
  padding: 6px 30px;
  font-size: 13px;
  line-height: 175%;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.sched-inter-right-file-label {
  margin-top: 40px;
}

.sched-inter-left-input-attach {
  margin-top: 70px;
}

/* schedule interview end */

/* modal start */
.modal {
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: fixed;
  top: 10vh;
  left: 20vw;
  right: 0;
  bottom: 0;
  padding: 41px 105px;
  z-index: 50;
  width: 60%;
  height: 80%;
  margin: auto;
  overflow-y: scroll;
}

.modal-close {
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000;
  opacity: 0.5;
}

.modal::-webkit-scrollbar {
  display: none;
}

/* modal end */

/* Assign job start */
/* .assjob-container{
  top: auto;
} */
.assjob-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 175%;
  color: #000000;
  text-align: center;
  margin-top: 40px;
}

.assjob-input-container {
  margin-top: 43px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.assjob-input-container-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
  width: 200px;
}

.assjob-input-container-label-input {
  background: #f8f8f8;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #000000;
}

.assjob-input-container-label-input::placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #000000;
}

.assjob-submit-btn {
  background: #3a57e8;
  box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
  border-radius: 4px;
  padding: 13px 114px;
  text-align: center;
  width: 259px;
  margin: 38px auto 0 auto;
  cursor: pointer;
  color: #ffffff;
}

.editor2 {
  margin-bottom: 50px;
}

/* Assign job end */

/* .job-opening-info {
  padding-top: 64px; 
} */

.candidateBlueCard{
  background-color: #c4cdf8;
  box-shadow: rgba(58, 87, 232, 0.3);
  border-radius: 10px;
  padding: 2%;
}
.quickaccess{
  text-decoration: none;
  color: #000000;
  display: block;
  margin-top: 4%;
}

@media screen and (max-width: 780px) {
  .quickaccess {
    margin-top: 2%;
    font-size: 14px;
  }
}
.canhead{
  color: #557fff;
  text-align: center;
  margin-top: 2%
}
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;


}
.scrolling-wrapper-flexbox > .card {
  flex: 0 0 auto;
}
.row{
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.card {
  /*float: left;*/
  max-width: 25.333%;
  padding: .75rem;
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 33.333%;
  flex-grow: 0;
  flex-shrink: 0;
}

.card > img {
  margin-bottom: .75rem;
  width: 100%;
}

.card-text {
  font-size: 85%;
}


/* TO do */
.app-wrapper {
  background-color: #12343b;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
}
.task-input{
  outline: none;
  padding: 15px;
  margin-right: 25px;
  font-size: 20px;
  color: #ccc;
  background-color: #000000;
  border: 1px solid #c89666;
  border-radius: 10px;
}
.button-add{
  width: 70px;
  padding: 15px 15px;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  background-color: #f1af71;
  cursor: pointer;
}
.list-item {
  display: flex;
  width: 50%;
  margin: 20px 0 10px 5%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  /* height: 30px; */
}
.button-complete, .button-delete{
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 28px;
  border-radius: 50%;
}
.button-complete{
  color: #ff6c6c;
  margin-right: 10px;
}
.button-delete{
  color: lightseagreen;
}
.complete{
  text-decoration-style: solid;
  text-decoration-line: line-through;
  text-decoration-color: blue;
  opacity: 0.6;
}
.list{
  width: 220px;
  border: none;
  color: #000000;
  font-size: 20px;
  padding-left: 10px;
  margin-right: 15px;
}
input:focus,
button:focus{
  outline: none;
}
.btnstodo{
  margin-left: 20%;
}
.interviewPic{
  width: 50px;
}

.MuiTableCell-root {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTableContainer-root {
  overflow-x: auto;
}

.tss-1cn2w2t-MUIDataTableBodyCell-root {
  text-align: left !important;
}

.dropdown-content{
  z-index: 1000 !important;
}

.MuiTableRow-root th span {
  justify-content: left !important;
}

.css-5bjqim-MuiPaper-root-MuiPopover-paper{
  top: 15% !important;
  height: auto !important;
  max-height: 80vh; /* Keep max-height to prevent overflow */
  overflow-y: auto;
}

@media (min-width: 1200px) and (max-width: 1370px) {
  .card-set-1366{
    margin-top: -2% !important;
  }
}
