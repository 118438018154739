.container {
    display: flex;
    height: 525px;
    padding: 10px;
    border-radius: 16px;
    background: #FFF;
    margin: auto;
}

.tab-button,
.tab-button1,
.tab-button2,
.tab-button3,
.tab-button4 {
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px 16px 0px 0px;
    margin-right: 5px;
}

.data-div {
    width: 100%;
    height: 104px;
    border-radius: 16px 16px 16px 16px;
    background: #FCFCFC;
}

::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }