.InstituteTitle{
    text-align: center;
    color: #2A72DE;
}
.InstitutePhotoAddress{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    gap: 5%;
}
.InstituteDescription{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10%;
}
.JobInnerCard{
    padding: 24px;
}
.JobCardImgDiv{
    width: 71px;
    height: 60px;
}
.JobCardImgDiv img{
    width: 71px;
    height: 60px;
    border-radius: 10px;
}
.applicationTop{
    display: flex;
    flex-direction: row;
    padding-top: 2%;
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: #ffffff;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    text-align: center;
  }
.next_btn{
    margin-left: auto;
    padding: 0 16px; 
    padding-top:1rem;
    padding-bottom: 1rem;
    display: flex; 
    align-items: center; 
    justify-content: end; 
    font-family: Poppins; /* Body */
    font-size: 16px; 
    line-height: 26px; 
    color: #FFFFFFFF; /* white */
    background: #535CE8FF; /* primary-500 */
    opacity: 1; 
    border: none; 
    border-radius: 6px; /* border-m */
}
.ApplicationSteps{
    display: flex;
    justify-content: center;
    margin-top: 4%;
}
.changebodycolor{
    margin: 0%;
    background-color: #ffffff;
  min-height: 100vh;
}


