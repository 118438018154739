.MuiTypography-root{
    position: sticky;
    top:0;
    background-color: white;
    z-index: 1;
}
.css-njmah9-MuiDialogContent-root{
    overflow: hidden auto;
    height: 550px;
}
.css-r94wvd-MuiDialogActions-root{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: white;
}